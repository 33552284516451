import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import LinkButton from '../components/buttons/link-button';
import SEO from '../components/seo';
import {
  useAddItemToCart,
  useCartItems,
  useCartTotals,
  useCheckout,
  useRemoveItemFromCart,
  useUpdateCartItem,
} from '../context/StoreContext';

const CartPage = () => {
  const toast = useToast();
  const {
    allShopifyProductVariant: { nodes: variants },
    allShopifyProduct: { nodes: products },
  } = useStaticQuery(graphql`
    query {
      allShopifyProductVariant {
        nodes {
          shopifyId
          product {
            media {
              ... on ShopifyMediaImage {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 400)
                    }
                  }
                }
              }
            }
          }
        }
      }
      allShopifyProduct {
        nodes {
          handle
          variants {
            shopifyId
            price
          }
        }
      }
    }
  `);

  const lineItems = useCartItems();
  const { tax, total } = useCartTotals();
  const removeItemFromCart = useRemoveItemFromCart();
  const checkout = useCheckout();
  const addItemToCart = useAddItemToCart();
  const updateCartItem = useUpdateCartItem();

  const removeItem = async (item) => {
    try {
      await removeItemFromCart(item.id);
      toast({
        title: 'Removed from cart',
        status: 'success',
        duration: 5000,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const decreaseQuantity = async (item) => {
    if (item.quantity === 1) {
      removeItem(item);
      return;
    }
    try {
      await updateCartItem([{ id: item.id, quantity: item.quantity - 1 }]);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const betterProductHandles = products.map(({ handle, variants }) => {
    const newVariants = variants.map((variant) => variant.shopifyId);
    return {
      variants: newVariants,
      handle,
    };
  });

  function getHandleForVariant(variantId) {
    const selectedProduct = betterProductHandles.find((product) => {
      return product.variants.includes(variantId);
    });

    return selectedProduct ? selectedProduct.handle : null;
  }

  function getImageFluidForVariant(variantId) {
    const selectedVariant = variants.find((variant) => {
      return variant.shopifyId === variantId;
    });

    if (selectedVariant) {
      return getImage(selectedVariant.product.media[0].image.localFile);
    }
    return null;
  }

  const LineItem = ({ item }) => {
    const addToCart = async () => {
      await addItemToCart(item.variant.id, 1);
    };
    console.log(item);
    console.log('Price and quantity', item.variant.price, item.quantity);
    return (
      <Flex
        justifyContent="space-between"
        w="100%"
        my={4}
        alignItems="flex-start"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDir="row"
          w="100%"
        >
          <Box
            boxShadow="md"
            w={{ base: '50%', md: '70%', lg: '30%' }}
            overflow="hidden"
            h="auto"
            mr={8}
          >
            <Img
              image={getImageFluidForVariant(item.variant.id)}
              imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
              alt=""
            />
          </Box>

          <Box w="50%">
            <Link to={`/product/${getHandleForVariant(item.variant.id)}`}>
              <Text fontSize="1.2rem" fontWeight="bold">
                {item.title}
              </Text>
            </Link>
          </Box>
        </Flex>
        <Flex
          w="100%"
          justifyContent="space-between"
          flexDir={{ base: 'column', md: 'row' }}
          mt={{ base: 4, md: 0 }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            my={{ base: 4, md: 0 }}
            order={{ base: 1 }}
            px={2}
            py={2}
            bgColor="white"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="2xl"
            w="100%"
            mr={3}
            maxW="6rem"
          >
            <Text fontWeight="semibold">
              $
              {(
                parseFloat(item.variant.priceV2.amount) *
                parseInt(item.quantity)
              ).toFixed(2)}
            </Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            order={{ base: 2 }}
          >
            <IconButton
              icon={<MinusIcon />}
              aria-label="Decrease quantity"
              onClick={() => decreaseQuantity(item)}
            />
            <Text color="gray.800" fontWeight="semibold" mx={8}>
              {item.quantity}
            </Text>
            <IconButton
              icon={<AddIcon />}
              aria-label="Increase quantity"
              onClick={addToCart}
            />
          </Flex>

          <Button
            variant="outline"
            onClick={() => removeItem(item)}
            order={{ base: 3 }}
            mt={{ base: 4, md: 0 }}
          >
            Remove
          </Button>
        </Flex>
      </Flex>
    );
  };

  const emptyCart = (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      w="100%"
      mt={16}
      mb={8}
    >
      <SEO title="Cart" />
      <Heading>Cart</Heading>
      <Text my={4}>Your shopping cart is empty.</Text>
      <Divider mb={4} />
      <LinkButton text="Go to Shop" link="shop" />
    </Flex>
  );

  return lineItems.length < 1 ? (
    emptyCart
  ) : (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      w="100%"
      mt={16}
      mb={8}
    >
      <SEO title="Cart" />
      <Heading mb={4}>Cart</Heading>
      {lineItems.map((item) => (
        <React.Fragment key={item.id}>
          <LineItem key={item.id} item={item} />
          <Divider my={3} />
        </React.Fragment>
      ))}
      <Flex w="100%" flexDir="column">
        <Flex
          p={4}
          ml="0"
          flexDir="column"
          minW="20rem"
          w={{ base: '100%', lg: '30%' }}
          alignSelf="flex-end"
        >
          <Flex flexDir="column" w="100%">
            <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
              <Text>Subtotal:</Text>
              <Text ml="auto">{total}</Text>
            </Flex>
            {/* TODO: add in shipping costs */}
            <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
              <Text>Shipping:</Text>
              <Text ml="auto">Calculated at checkout</Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              alignItems="flex-start"
              mb={3}
            ></Flex>
          </Flex>

          <Divider />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt={4}
            fontSize="lg"
          >
            <Text fontWeight="semibold">Total without shipping:</Text>
            <Text fontWeight="semibold" ml="auto">
              {total}
            </Text>
          </Flex>
          <Button mt={4} w="100%" onClick={checkout} colorScheme="secondary">
            Checkout
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CartPage;
